// /*
//  * @Author: wuruoyu
//  * @Description: 路由配置
//  * @Date: 2020-12-22 10:19:38
//  * @LastEditors: Chen
//  * @LastEditTime: 2021-01-13 11:39:15
//  * @FilePath: \huayun_platform\src\router\index.js
//  */
// import Vue from 'vue'
// import VueRouter from 'vue-router'

// Vue.use(VueRouter)

// const Login = () => import('@/views/login');
// const Home = () => import('@/views/home');
// const Layout = () => import('@/layout');

// export const menuRoute = {
//   path: '/',
//   name: 'Layout',
//   redirect: '/platformmanage/personnal',
//   component: Layout,
//   children: [
//     {
//       path: 'platformmanage',
//       name: 'PlatformManage',
//       component: () => import('@/views/platformManage/index'),
//       meta: {
//         title: '平台管理',
//         icon: 'el-icon-suitcase-1'
//       },
//       children: [
//         {
//           path: 'personnal',
//           name: 'Personnal',
//           component: () => import('@/views/platformManage/personnal'),
//           meta: {
//             title: '人员管理',
//             icon: 'el-icon-s-custom',
//             hash: '/platformmanage/personnal'
//           },
//         },
//         {
//           path: 'project',
//           name: 'Project',
//           component: () => import('@/views/platformManage/project'),
//           meta: {
//             title: '项目组织',
//             icon: 'el-icon-bank-card',
//             hash: '/platformmanage/project'
//           },
//         },
//         {
//           path: 'limits',
//           name: 'Limits',
//           component: () => import('@/views/platformManage/limits'),
//           meta: {
//             title: '权限管理',
//             icon: 'el-icon-lock',
//             hash: '/platformmanage/limits'
//           },
//         }
//       ]
//     }
//   ]
// }

// const routes = [
//   menuRoute,
//   // {
//   //   path: '/login',
//   //   name: 'Login',
//   //   component: Login
//   // },
//   // {
//   //   path: '/home',
//   //   name: 'Home',
//   //   component: Home
//   // },
// ]

// //获取原型对象上的push函数
// const originalPush = VueRouter.prototype.push
// //修改原型对象中的push方法
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }

// const router = new VueRouter({
//   mode: 'hash',
//   base: process.env.BASE_URL,
//   routes
// })

// export default router


/*
 * @Author: wuruoyu
 * @Description: 路由配置
 * @Date: 2020-12-22 10:19:38
 * @LastEditors: wuruoyu
 * @LastEditTime: 2020-12-31 14:14:13
 * @FilePath: \huayun_platform\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Login = () => import('@/views/login');
const Home = () => import('@/views/home');
const Layout = () => import('@/layout');

export const menuRoute = {
  path: '/',
  name: 'Layout',
  redirect: '/energyserve',
  component: Layout,
  children: [{
    path: 'energyserve',
    name: 'EnergyServe',
    component: () => import('@/views/energyService/index'),
    meta: {
      title: '智慧供热',
      icon: 'el-icon-s-marketing'
    }
  }]
}

const routes = [
  menuRoute,
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: Login
  // },
  // {
  //   path: '/home',
  //   name: 'Home',
  //   component: Home
  // },
]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router

