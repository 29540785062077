// 对数字进行四舍五入，保留两位小数的过滤器
export function keepNumBitFilter(value, fixedNum) {
  if (fixedNum == undefined) {
    fixedNum = 2;
  }
  let realVal = ''
  if (value) {
    realVal = parseFloat(value).toFixed(fixedNum)
  } else {
    realVal = '--'
  }
  return realVal
}

// 将小数转化为百分比，保留整数
export function keepNumPercent(value, fixedNum) {
  if (fixedNum == undefined) {
    fixedNum = 0
  }
  let str = Number(value * 100).toFixed(fixedNum);
  str += "%"
  return str
}

// 为数字添加分隔号
export function thousandBitSeparator(num) {
  if (num) {
    var c = (num.toString().indexOf('.') !== -1) ? num.toLocaleString() :
      num.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
    return c
  }
}

// 切割过长的字符串（以字符为单位）
export function wrapStr(value, len) {
  let leng = 0;
  for (var i = 0; i < value.length; i++) {
    var a = value.charAt(i);
    if (a.match(/[^\x00-\xff]/ig) != null) {
      leng += 2;
    }
    else {
      leng += 1;
    }
  }
  if (leng / 2 > len) {
    return value.substring(0, len - 1) + '...'
  } else {
    return value
  }
}

// 切割过长的字符串（以字节为单位）
export function wrapStrByByte(value, len) {
  if (undefined === value || null === value) {
    value = ''
  }
  let result = ''
  value = value.toString()
  let strlen = value.length
  let chrlen = value.replace(/[^x00-\xff]/g, '**').length
  if (chrlen <= len) {
    return value
  }
  for (let i = 0, j = 0; i < strlen; i++) {
    let chr = value.charAt(i)
    if (/[x00-\xff]/.test(chr)) {
      j++
    } else {
      j += 2
    }
    if (j <= len) {
      result += chr
    } else {
      return result + '...'
    }
  }
}