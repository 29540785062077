<!--
 * @Author: wuruoyu
 * @Description: 
 * @Date: 2020-12-22 10:19:38
 * @LastEditors: wuruoyu
 * @LastEditTime: 2020-12-22 14:28:25
 * @FilePath: \huayun_platform\src\App.vue
-->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
