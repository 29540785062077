import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tenantId: 18,
    projectId: 2,
    userId:1,
    projectName: '北七家人才基地',
    userInfo: {},
    serviceHeatingFlag: 'FW_00005'
  },
  mutations: {
    PROJECT_ID(state,id){
      state.projectId = id;
    },
    PORJECT_NAME(state,name){
      state.projectName = name;
    },
    TENANT_ID(state,id){
      state.tenantId = id;
    },
    SET_USER_INFO(state, payload){
      state.userInfo = payload
    },
    SET_USER_ID(state, payload){
      state.userId = payload
    }
  },
  actions: {},
  modules: {
  }
})
